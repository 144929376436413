import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const response = await axios.get("/api/auth/profile");
        setUser(response.data);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    initializeAuth();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      window.history.replaceState({}, document.title, window.location.pathname);
      const fetchUserAfterGoogleLogin = async () => {
        try {
          const response = await axios.get("/api/auth/profile");
          setUser(response.data);
        } catch (error) {
          console.error(
            "Failed to fetch user profile after Google login:",
            error
          );
          setUser(null);
        }
      };
      fetchUserAfterGoogleLogin();
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post("/api/auth/login", { email, password });

      // If verification is required, return that information
      if (response.data.requiresVerification) {
        return {
          requiresVerification: true,
          email: response.data.email,
          error: response.data.error,
        };
      }

      // If login is successful
      if (response.data.user) {
        setUser(response.data.user);
        return { user: response.data.user };
      }

      return response.data;
    } catch (error) {
      // Handle verification required error from error response
      if (error.response?.data?.requiresVerification) {
        return {
          requiresVerification: true,
          email: error.response.data.email,
          error: error.response.data.error,
        };
      }

      throw error;
    }
  };

const signup = async (userData) => {
  try {
    const response = await axios.post("/api/auth/signup", userData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (response.data.success) {
      return {
        success: true,
        message: response.data.message,
        emailSent: response.data.emailSent,
        email: response.data.email,
      };
    }
    return {
      success: false,
      error: response.data.error || "Registration failed",
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.error || "Failed to create account",
    };
  }
};
  const logout = async () => {
    try {
      await axios.post("/api/auth/logout");
      setUser(null);
      return true;
    } catch (error) {
      console.error("Logout error:", error);
      return false;
    }
  };

  const resendVerificationEmail = async (email) => {
    try {
      const response = await axios.post("/api/auth/resend-verification", {
        email,
      });
      return {
        success: true,
        message: response.data.message,
      };
    } catch (error) {
      return {
        success: false,
        error:
          error.response?.data?.error || "Failed to resend verification email",
      };
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = "/api/auth/google";
  };

  const forgotPassword = async (email) => {
    try {
      const response = await axios.post("/api/auth/forgot-password", { email });
      return {
        success: true,
        message: response.data.message,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to process request",
      };
    }
  };

  const resetPassword = async (token, password) => {
    try {
      const response = await axios.post("/api/auth/reset-password", {
        token,
        password,
      });
      return {
        success: true,
        message: response.data.message,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to reset password",
      };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        signup,
        logout,
        resendVerificationEmail,
        handleGoogleLogin,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
