// utils/notificationSetup.js
export const setupBrowserNotifications = async () => {
  if (!("Notification" in window)) {
    console.log("Browser does not support notifications");
    return;
  }

  if (Notification.permission !== "granted") {
    try {
      await Notification.requestPermission();
    } catch (error) {
      console.error("Failed to get notification permission:", error);
    }
  }
};
