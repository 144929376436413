// src/App.js
import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { PrivateRoute, PublicRoute } from "./middlewares/RouteGuards";
import LoadingSpinner from "./components/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "./contexts/ThemeContext";
import NavBar from "./components/shared/NavBar";
import { setupBrowserNotifications } from "./utils/notificationSetup";
import { NotificationProvider } from "./contexts/NotificationContext";
import { NotificationBell } from "./components/NotificationBell";

// Lazy loaded components
const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));
const EmailVerification = lazy(() => import("./pages/EmailVerification"));
const StudentDashboard = lazy(() =>
  import("./components/dashboards/StudentDashboard")
);
const InstructorDashboard = lazy(() =>
  import("./components/dashboards/InstructorDashboard")
);
const AdminDashboard = lazy(() =>
  import("./components/dashboards/AdminDashboard")
);
const Unauthorized = lazy(() => import("./pages/Unauthorized"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
          <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-bold text-red-600 dark:text-red-400 mb-4">
              Oops! Something went wrong
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              The application has encountered an unexpected error. Please try
              refreshing the page.
            </p>
            <button
              onClick={() => window.location.reload()}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition-colors"
            >
              Refresh Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Loading Component
const PageLoader = ({ children }) => (
  <Suspense
    fallback={
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    }
  >
    {children}
  </Suspense>
);

// Route Configuration
const routeConfig = [
  {
    path: "/login",
    element: Login,
    isPublic: true,
  },
  {
    path: "/signup",
    element: Signup,
    isPublic: true,
  },
  {
    path: "/forgot-password",
    element: ForgotPassword,
    isPublic: true,
  },
  {
    path: "/reset-password",
    element: ResetPassword,
    isPublic: true,
  },
  {
    path: "/verify-email",
    element: EmailVerification,
    isPublic: true,
  },
  {
    path: "/unauthorized",
    element: Unauthorized,
    isPublic: true,
  },
  {
    path: "/student/*",
    element: StudentDashboard,
    role: "student",
  },
  {
    path: "/instructor/*",
    element: InstructorDashboard,
    role: "instructor",
  },
  {
    path: "/admin/*",
    element: AdminDashboard,
    role: "admin",
  },
];

// Root redirect component
const RootRedirect = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (user) {
    return <Navigate to={`/${user.role}`} replace />;
  }

  return <Navigate to="/login" replace />;
};

// Preload function for route component
const preloadComponent = async (role) => {
  try {
    if (role === "student") {
      const module = await import("./components/dashboards/StudentDashboard");
      return module.default;
    } else if (role === "instructor") {
      const module = await import(
        "./components/dashboards/InstructorDashboard"
      );
      return module.default;
    } else if (role === "admin") {
      const module = await import("./components/dashboards/AdminDashboard");
      return module.default;
    }
  } catch (error) {
    console.error("Error preloading component:", error);
    return null;
  }
};

const App = () => {
   useEffect(() => {
     setupBrowserNotifications();
   }, []);
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <AuthProvider>
          <NotificationProvider>
            <Router>
              <NavBar />

              <PageLoader>
                <Routes>
                  {/* Map route configuration */}
                  {routeConfig.map(
                    ({ path, element: Element, isPublic, role }) => (
                      <Route
                        key={path}
                        path={path}
                        element={
                          isPublic ? (
                            <PublicRoute>
                              <PageLoader>
                                <Element />
                              </PageLoader>
                            </PublicRoute>
                          ) : (
                            <PrivateRoute role={role}>
                              <PageLoader>
                                <Element />
                              </PageLoader>
                            </PrivateRoute>
                          )
                        }
                      />
                    )
                  )}

                  {/* Admin section redirect */}
                  <Route
                    path="/admin/:section"
                    element={
                      <PrivateRoute role="admin">
                        <Navigate
                          to={(location) => {
                            const section = location.pathname.split("/").pop();
                            return `/admin?section=${section}`;
                          }}
                          replace
                        />
                      </PrivateRoute>
                    }
                  />

                  {/* Root redirect */}
                  <Route path="/" element={<RootRedirect />} />

                  {/* Catch all route */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </PageLoader>
            </Router>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </NotificationProvider>
        </AuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
