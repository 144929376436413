// frontend/src/services/socketService.js
import io from "socket.io-client";

class SocketService {
  constructor() {
    this.socket = null;
  }

  connect(userId) {
    if (!this.socket) {
      this.socket = io(
        process.env.REACT_APP_API_URL || "http://localhost:3000"
      );

      this.socket.on("connect", () => {
        console.log("Socket connected");
        this.socket.emit("join", userId);
      });

      this.socket.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });
    }
    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
}

export default new SocketService();
